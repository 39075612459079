<template>
    <v-card
        class="card-border d-flex flex-column justify-start align-center elevation-0 border-radius-1 mt-5 pb-0 w-100 position-relative"
    >
        <v-container>
            <v-row>
            <v-col cols="8">
                <span class="primary--text font-size-16">{{ title }}</span>
            </v-col>
            <v-col cols="4">
                <download-excel :data="getItemsToExport(tableData)" type="csv" :name="`${filename}.csv`" :fields="swap(fileFields)">
                            <v-btn color="secondary" outlined rounded class="text-capitalize pull-right"
                                ><v-icon class="mr-2">mdi-file-export-outline</v-icon
                                >{{ $t('export_csv') }}</v-btn
                            >
                </download-excel>
            </v-col>
        </v-row>
            <v-divider></v-divider>
            <v-col cols="12" class="d-flex flex-column">
                <v-row class="pa-0" v-for="(item, index) of items" :key="index">
                    <v-col cols="4">
                        <div class="d-flex justify-space-around flex-row">
                            <v-row class="pa-0">
                                <v-col
                                    cols="4"
                                    class="pa-0 d-flex justify-center align-center"
                                    align-self="center"
                                >
                                    <UserAvatar
                                        :user-name="$utils.getFullName(item.reader)"
                                        :avatar="item.reader.avatar"
                                    />
                                </v-col>
                                <v-col cols="8" class="pa-0 d-flex justify-start align-center">
                                    <span class="ml-1">{{ $utils.getFullName(item.reader) }}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="8">
                        <BeeProgressBar
                            :color="mainColor ? mainColor : color(item.percent)"
                            class="pa-0 text-center font-size-12"
                            :symbol="symbol"
                            :progress="item.percent"
                            >{{ `${item[searchKey]}${symbol}` }}
                        </BeeProgressBar>
                    </v-col>
                </v-row>
            </v-col>
        </v-container>
        <v-btn
            outlined
            rounded
            small
            color="secondary"
            class="show-button"
            @click="showAllRecords"
            >{{ showAll ? $t('library.show_minus') : `${$t('library.show_all')} (${tableData.length})` }}</v-btn
        >
    </v-card>
</template>

<script>
import UserAvatar from '../../core/UserAvatar'
import BeeProgressBar from '../../core/BeeProgressBar'
import logicShowAllMixin from "../../../mixins/logicShowAll";

export default {
    name: 'StatDetailTable',
    components: { BeeProgressBar, UserAvatar },
    data() {
        return {
            fileFields: {
                student: this.$t('students'),
                pages_read: this.$t('library.page_read_detail'),
                reading_minutes: this.$t('library.hours_read_per_student')
            }
        }
    },
    props: {
        title: { type: String, default: '' },
        mainColor: { type: String, default: '' },
        symbol: { type: String, default: '' },
        searchKey: { type: String, default: 'percent' },
        book: { type: Object, default: { title: ''} },
        assign: { type: Object, default: {startingDate: '', evaluationDate: ''} }
    },
    methods: {
        color(value = 0) {
            if (value <= 40) return '#514773'
            if (value >= 41 && value <= 59) return '#fddc61'
            return '#24dca9'
        },
        getItemsToExport(items) {
            let itemsToExport = [];
            if (items) {
                itemsToExport = items.map( (item) => {
                    return { 
                        student: `${item.reader.firstname} ${item.reader.lastname}`,
                        pages_read: `${item.percent}%`,
                        reading_minutes: `${item.value} min` 
                    }
                })
            }
            return itemsToExport
        },
        swap(obj){
            return Object.fromEntries(Object.entries(obj).map(a => a.reverse()))
        }
    },
    computed: {
        filename() {
            return `${this.book.title.replace(/\s/g,'_')}-${this.$moment(this.assign.startingDate).format('DD_MM')}-${this.$moment(this.assign.evaluationDate).format('DD_MM')}-pages-read`
        },
    },
    mixins: [logicShowAllMixin]
}
</script>

<style scoped>
    .card-border {
        border: #e9e6f0 2px solid;
    }

    .pull-right{
        float: right;
    }
</style>
