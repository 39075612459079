<template>
    <v-container class="my-0 py-0 round-xl">
        <!-- Refactor this button into a global component -->
        <v-btn
            color="deep-purple lighten-5"
            rounded
            elevation="0"
            small
            class="text-capitalize mb-6"
            @click="$router.go(-1)"
        >
            <v-icon class="mr-1" color="deep-purple" small>mdi-arrow-left</v-icon>
            <span class="black--text font-weight-bold">
                {{ $t('library.goBack') }}
            </span>
        </v-btn>
        <v-card elevation="0" class="mb-6" rounded="xl">
            <v-row>
                <v-col cols="12">
                    <h2 class="pa-4 my-3">
                        {{ $t('assignments.titles.assignmentGeneralInformation') }}
                    </h2>
                    <hr />
                </v-col>
            </v-row>

            <!-- Book data -->
            <AssignedBooksTable
                :headers="assignedBooksHeaders"
                :items="currentBookSelected"
                :hideDefaultFooter="true"
                :loading="loadingData"
                :itemsPerPage="1"
                class="my-5"
            />
        </v-card>

        <v-row class="my-5">
            <v-col cols="12" md="6">
                <v-card rounded="xl" elevation="0" height="300px">
                    <v-row>
                        <v-col cols="12">
                            <h2 class="pa-4 my-2">
                                {{ $t('assignments.titles.generalPerformance') }}
                            </h2>
                            <hr />
                        </v-col>
                    </v-row>

                    <v-row align-content="center" justify="center" class="px-5 pb-6">
                        <v-col cols="6">
                            <v-card
                                class="deep-purple lighten-5 w-100 pa-3 d-flex flex-column text-center align-center justify-content-center"
                                rounded="lg"
                                elevation="0"
                                height="120px"
                            >
                                <strong
                                    class="primary--text body-2 font-weight-bold text-center my-1"
                                    >{{
                                        $t('assignments.placeholders.readingComprenhension')
                                    }}</strong
                                >
                                <v-progress-circular
                                    :size="81"
                                    :width="19"
                                    :value="readingComprehensionAverage"
                                    color="primary"
                                    rotate="90"
                                    :indeterminate="loadingData"
                                >
                                    <span class="caption font-weight-bold" v-if="!loadingData">
                                        {{ readingComprehensionAverage }}%</span
                                    >
                                </v-progress-circular>
                            </v-card>
                        </v-col>

                        <v-col cols="6">
                            <v-card
                                class="deep-purple lighten-5 w-100 pa-3 d-flex flex-column text-center align-center justify-content-center"
                                rounded="lg"
                                elevation="0"
                                height="120px"
                            >
                                <strong
                                    class="primary--text body-2 font-weight-bold text-center my-1"
                                    >{{ $t('assignments.placeholders.progress') }}</strong
                                >
                                <v-progress-circular
                                    :size="81"
                                    :width="19"
                                    :value="progressAverage"
                                    color="primary"
                                    rotate="90"
                                    :indeterminate="loadingData"
                                >
                                    <span class="caption font-weight-bold" v-if="!loadingData">
                                        {{ progressAverage }}%</span
                                    >
                                </v-progress-circular>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <!-- Book information data -->
            <v-col cols="12" md="6">
                <v-card rounded="xl" elevation="0" height="300px">
                    <v-row>
                        <v-col cols="12">
                            <h2 class="pa-4 my-2">{{ $t('library.book_information') }}</h2>
                            <hr />
                        </v-col>
                    </v-row>

                    <v-row align-content="center" justify="center" class="px-5 pb-6">
                        <v-col cols="6">
                            <v-card
                                class="deep-purple lighten-5 w-100 pa-3 d-flex flex-column text-center align-center justify-content-center"
                                rounded="lg"
                                elevation="0"
                                height="120px"
                            >
                                <h4 class="primary--text body-2 font-weight-bold my-1">
                                    {{ $t('assignments.placeholders.pagesAmount') }}
                                </h4>

                                <v-row
                                    align-content="center"
                                    justify="center"
                                    class="d-flex flex-row flex-nowrap align-center justify-center"
                                >
                                    <div class="deep-purple lighten-4 rounded-pill pa-4">
                                        <v-icon color="primary">mdi-book</v-icon>
                                    </div>
                                    <v-progress-circular
                                        :size="12"
                                        :width="3"
                                        color="primary"
                                        rotate="90"
                                        indeterminate
                                        v-if="loadingData"
                                        class="mx-2"
                                    >
                                    </v-progress-circular>
                                    <strong class="primary--text text-h6 font-weight-bold mx-2" v-else>
                                        {{ book?.totalPages || 0 }}</strong
                                    >
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <v-card
                                class="deep-purple lighten-5 w-100 pa-3 d-flex flex-column text-center align-center justify-content-center"
                                rounded="lg"
                                elevation="0"
                                height="120px"
                            >
                                <h4 class="primary--text body-2 font-weight-bold my-1">
                                    {{ $t('assignments.placeholders.totalQuestions') }}
                                </h4>

                                <v-row
                                    align-content="center"
                                    justify="center"
                                    class="d-flex flex-row flex-nowrap align-center justify-center"
                                >
                                    <div class="deep-purple lighten-4 rounded-pill pa-4">
                                        <v-icon color="primary">mdi-help-circle-outline</v-icon>
                                    </div>
                                    <v-progress-circular
                                        :size="12"
                                        :width="3"
                                        color="primary"
                                        rotate="90"
                                        indeterminate
                                        v-if="loadingData"
                                        class="mx-2"
                                    >
                                    </v-progress-circular>
                                    <strong class="primary--text text-h6 font-weight-bold mx-2" v-else>
                                        {{ book?.totalQuestions || 0 }}</strong
                                    >
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <!-- Book skill students data -->
            <v-col cols="12">
                <v-card rounded="xl" elevation="0" class="my-4">
                    <v-row>
                        <v-col cols="12">
                            <h2 class="pa-4 my-2">
                                {{ $t('reports.dashboard.cards.studentsPerformance') }}
                            </h2>
                            <hr />

                            <v-card
                                color="transparent"
                                rounded="lg"
                                class="my-6 px-4"
                                elevation="0"
                            >
                                <Table
                                    :headers="generateHeadersForPFSTable"
                                    :items="studentsMetrics"
                                    :loading="loadingData"
                                    withPerformanceSkills
                                    with-book-progress
                                    with-student-name
                                    with-reading-time
                                    :studentNameCallback="null"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CardStats from '../core/CardStats'
import Card from '../reports/shared/card/index.vue'
import Table from '../reports/shared/table/index.vue'
import BadgeIndicator from './core/BadgeIndicator'
import courseBook from '../../services/courseBook'
import courseBookStats from '../../services/courseBookStats'
import StatDetailTable from './core/StatDetailTable'
import AccuracyTable from './core/AccuracyTable'
import AssignedBooksTable from './shared/tables/assignedBooks/index.vue'
import PieChart from './shared/graphs/pieChart/index.vue'
import reportsServices from '../../services/reports/generalInformation'

import Author from '../../mixins/author'

export default {
    name: 'AssigmentStats.vue',
    components: {
        BadgeIndicator,
        CardStats,
        StatDetailTable,
        AccuracyTable,
        AssignedBooksTable,
        PieChart,
        Card,
        Table,
    },
    props: {
        assigmentId: { type: [Number, String], default: 0 },
    },
    data() {
        return {
            book: null,
            assign: null,
            course: null,
            courseBook: null,
            loadingData: false,
            showCharts: false,
            pageReadData: [],
            totalPagesRead: 0,
            questionsData: [],
            origindalQuestionsData: [],
            totalQuestions: 0,
            hoursData: [],
            totalHours: 0,
            cardSelected: 0,
            mapHoursData: [],
            mapPagesRead: [],
            studentsMetrics: [],
            progressAverage: 0,
            readingComprehensionAverage: 0,
        }
    },
    mixins: [Author],
    async mounted() {
        this.loadingData = true
        const response = await courseBook
            .getByCourseBookId(this.assigmentId)
            .catch((e) => console.error(e))

        this.courseBook = response.content
        const {
            book,
            course,
            id,
            bookState,
            correctAnswerPercentage,
            evaluationDate,
            progressPercentage,
            readingPercentage,
            startingDate,
        } = response.content[0]
        this.book = book
        this.course = course

        this.assign = {
            id,
            bookState,
            correctAnswerPercentage,
            evaluationDate,
            progressPercentage,
            readingPercentage,
            startingDate,
        }

        const responseCourseBook = await courseBookStats
            .getDataByEvaluationDate(course.id, book.id, this.assigmentId, evaluationDate)
            .catch(console.error)

        const { pagesReadDTO, questionsDTO, readingHoursDTO } = responseCourseBook.content
        const { pageReadData, questionsData, hoursData, origindalQuestionsData } = this.orderData(
            pagesReadDTO,
            questionsDTO,
            readingHoursDTO
        )
        this.pageReadData = [...pageReadData]
        this.totalPagesRead = parseInt(pagesReadDTO.totalPagesRead)
        this.mapPagesRead = this.mapCountPercent(this.pageReadData, 'value')

        this.origindalQuestionsData = [...origindalQuestionsData]
        this.questionsData = [...questionsData]
        this.totalQuestions = parseInt(questionsDTO.totalQuestions)

        this.hoursData = [...hoursData]
        this.totalHours = (parseInt(readingHoursDTO.totalHoursRead) / 60).toFixed()
        this.mapHoursData = this.mapCountPercent(this.hoursData, 'value')

        const courseBookMetrics = await reportsServices.geteCourseBookMetrics(
            course.id,
            startingDate,
            evaluationDate,
            this.assigmentId
        )
        this.progressAverage = courseBookMetrics.courseBookProgressAverage.toFixed(0)
        this.readingComprehensionAverage =
            courseBookMetrics.courseBookReadingComprehensionAverage.toFixed(0)
        this.studentsMetrics = courseBookMetrics.studentsData.map((metrics) => ({
            student: {
                ...metrics.student,
                name: metrics.student.fullUserName,
            },
            locate: {
                ...metrics.bookAssignedMetricInfo.locateSkillData,
                corrects: metrics.bookAssignedMetricInfo.locateSkillData.totalCorrectAnswers,
                total: metrics.bookAssignedMetricInfo.locateSkillData.totalAnswers,
            },
            integrate: {
                ...metrics.bookAssignedMetricInfo.interpretationSkillData,
                corrects:
                    metrics.bookAssignedMetricInfo.interpretationSkillData.totalCorrectAnswers,
                total: metrics.bookAssignedMetricInfo.interpretationSkillData.totalAnswers,
            },
            reflect: {
                ...metrics.bookAssignedMetricInfo.refAndValSkillData,
                corrects: metrics.bookAssignedMetricInfo.refAndValSkillData.totalCorrectAnswers,
                total: metrics.bookAssignedMetricInfo.refAndValSkillData.totalAnswers,
            },
            readingComprehension: metrics.bookAssignedMetricInfo.readingComprehensionAverage,
            bookProgress: metrics.bookAssignedMetricInfo.progress,
            totalAnswers:
                metrics.bookAssignedMetricInfo.locateSkillData.totalAnswers +
                metrics.bookAssignedMetricInfo.interpretationSkillData.totalAnswers +
                metrics.bookAssignedMetricInfo.refAndValSkillData.totalAnswers,
            readingTime: this.formatReadingTime(metrics.bookAssignedMetricInfo.lectureTime),
        }))

        this.loadingData = false
        this.showCharts = true
    },
    computed: {
        assignedBooksHeaders() {
            return [
                {
                    text: this.$t('assignments.titles.assignmentTableTitles.bookTitle'),
                    align: 'start',
                    sortable: false,
                    value: 'book',
                },
                {
                    text: this.$t('assignments.titles.assignmentTableTitles.assignTo'),
                    align: 'center',
                    sortable: false,
                    value: 'assignedTo',
                },
                {
                    text: this.$t('assignments.titles.assignmentTableTitles.startDate'),
                    align: 'center',
                    sortable: false,
                    value: 'startDate',
                },
                {
                    text: this.$t('assignments.titles.assignmentTableTitles.endDate'),
                    align: 'center',
                    sortable: false,
                    value: 'endDate',
                },
            ]
        },
        generateHeadersForPFSTable() {
            return [
                {
                    text: this.$t('reports.tableHeaders.student'),
                    align: 'start',
                    sortable: false,
                    value: 'student',
                    class: 'text-no-wrap',
                },
                {
                    text: this.$t('reports.tableHeaders.progress'),
                    align: 'center',
                    sortable: false,
                    value: 'bookProgress',
                    width: '13%',
                },
                {
                    text: this.$t('reports.skills.fullName.readingTime'),
                    align: 'center',
                    sortable: false,
                    value: 'readingTime',
                    width: '13%',
                },
                {
                    text: this.$t('reports.skills.fullName.locate'),
                    align: 'center',
                    sortable: false,
                    value: 'locate',
                    width: '13%',
                },
                {
                    text: this.$t('reports.skills.fullName.integrate'),
                    align: 'center',
                    sortable: false,
                    value: 'integrate',
                    width: '13%',
                },
                {
                    text: this.$t('reports.skills.fullName.reflect'),
                    align: 'center',
                    sortable: false,
                    value: 'reflect',
                    width: '13%',
                },
                {
                    text: this.$t('reports.dashboard.cards.readingComprehension'),
                    align: 'center',
                    sortable: true,
                    value: 'readingComprehension',
                    class: 'text-no-wrap',
                    width: '13%',
                },
                {
                    text: 'Preguntas contestadas',
                    align: 'center',
                    sortable: true,
                    value: 'totalAnswers',
                    class: 'text-no-wrap',
                    width: '13%',
                },
            ]
        },
        currentBookSelected() {
            return this.courseBook || []
        },
        getReadingComprehrensionGraphData() {
            return [
                {
                    name: 'Reading Comprehension',
                    value: Number(80),
                },
            ]
        },
    },
    methods: {
        mapCountPercent(array, mapKey = '') {
            if (array.length) {
                return array.map((el) => el[mapKey])
            }
            return []
        },
        setCardSelected(card = 0) {
            this.cardSelected = card
        },
        orderData(pagesRead, questions, readingHours) {
            const pageReadData = pagesRead.pagesReadByStudent

            const origindalQuestionsData = this.mapCountPercent(
                questions.students,
                'correctAnswers'
            )
            const questionsData = questions.students
            questionsData
                .sort((a, b) => (a.accuracy > b.accuracy ? 1 : a.accuracy < b.accuracy ? -1 : 0))
                .reverse()

            const hoursData = readingHours.readingHoursByStudent.map((el, index) => ({
                ...el,
                percent: pagesRead.pagesReadByStudent[index].percent,
            }))

            return {
                pageReadData,
                questionsData,
                hoursData,
                origindalQuestionsData,
            }
        },
        formatReadingTime(readingTime) {
            if(readingTime === 0) return null
            const HOUR_TIME = 1
            return readingTime >= HOUR_TIME
                ? `${readingTime.toFixed(1)} h`
                : this.convertHoursToMinutes(readingTime)
        },
        convertHoursToMinutes(hours) {
            const minutes = hours * 60
            return `${minutes.toFixed(0)} min`;
        }
    },
}
</script>

<style scoped>
.skills-card {
    box-sizing: border-box;
    border: 2px solid #e9e6f0 !important;
    border-radius: 6px !important;
}
</style>
